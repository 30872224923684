import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { AuthTokenResponse, User } from '../auth.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  http = inject(HttpClient);
  public authenticate(data: IAuthenticateData) {
    return this.http.post<AuthTokenResponse>(
      environment.apiUrl + AuthApiRoutes.login,
      data,
    );
  }

  public getUser() {
    return this.http.get<User>(environment.apiUrl + AuthApiRoutes.authorize);
  }
  public changePassword(data: IChangePasswordData) {
    return this.http.put<any>(
      environment.apiUrl + AuthApiRoutes.changePassword,
      data,
    );
  }
  public changePasswordAdmin(data: IChangePasswordAdminData) {
    return this.http.put<any>(
      environment.apiUrl + AuthApiRoutes.changePasswordAdmin(data.userId),
      data,
    );
  }

  public verifyToken(token: string) {
    return this.http.get<any>(environment.apiUrl + AuthApiRoutes.verify, {
      params: { token },
    });
  }

  public resetPassword(body: IResetPasswordData) {
    return this.http.put<any>(
      environment.apiUrl + AuthApiRoutes.resetPassword,
      body,
    );
  }
}

export interface IAuthenticateData {
  email: string;
  password: string;
}

export const AuthApiRoutes = {
  login: '/api/v1/user/identity/account/token',
  authorize: '/api/v1/user/identity/account',
  changePassword: '/api/v1/user/identity/account/password',
  changePasswordAdmin: (userId: string) =>
    `/api/v1/cms/user/${userId}/password`,
  verify: '/api/v1/user/identity/password-reset/verify',
  resetPassword: '/api/v1/user/identity/password-reset',
};

export interface IChangePasswordAdminData {
  userId: string;
  newPassword: string;
  confirmPassword: string;
}

export interface IChangePasswordData {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export interface IResetPasswordData {
  token: string;
  newPassword: string;
  confirmPassword: string;
}
